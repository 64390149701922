<template>
  <ul id="sortable" class="list-group">
    <li v-for="(item , index) in items " class="list-group-item position" :data-order="item.id" style="cursor: grab;">
      <i class="fa fa-arrows-v m-2"></i>
        <span style="margin-left:1rem"><strong>Title : </strong> {{item['title']}}</span>
    </li>
  </ul>
</template>

<script>
    export default {
        props: {
          items: {
            type: Array,
          }
        },
        mounted() {
          $("#sortable").sortable();
        },
    }
</script>
