if($('.banner-slick').length > 0){
    $('.banner-slick').slick({
        arrows:true,
        dots: $(".banner-slick-slide").length > 1,
        prevArrow: $('.banner-prev'),
        nextArrow: $('.banner-next'),
    });
}

// $('.banner-prev').on('click', function() {
//     $('.banner-slick').slick("slickPrev");
// });

// $('.banner-next').on('click', function() {
//     $('.banner-slick').slick("slickNext");
// });

// if($('.banner-block').length > 0){
//     $('.banner-block').slick({
//         arrows:false,
//     });
// }
/*
if($('.news-slide').length > 0){
    $('.news-slide').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        arrows: true,
        dots: true,
    });
}
*/